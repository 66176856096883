<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-search">
          <el-form size="mini" :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item label="用户">
              <el-input v-model="searchCondition.user_id" placeholder="输入用户id" clearable></el-input>
            </el-form-item>
            <el-form-item label="QID">
              <el-input v-model="searchCondition.qid" placeholder="输入qid" clearable></el-input>
            </el-form-item>
            <el-form-item label="类型">
<!--              <el-input v-model="searchCondition.channel" placeholder="输入类型" clearable></el-input>-->
              <el-select v-model="searchCondition.channel" allow-create clearable placeholder="输入类型">
                <el-option value="SQL" label="SQL"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发布时间">
              <el-date-picker
                  v-model="searchCondition.time_value"
                  type="datetimerange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchButton" icon="el-icon-search">查询</el-button>
              <el-button type="danger" @click="clearLogBtn" plain icon="el-icon-delete">清空日志</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <ApeTable ref="apeTable" :data="sysLogList" :columns="columns" :loading="loadingStaus" :pagingData="pagingData"
                  @switchPaging="switchPaging" highlight-current-row border align=center>
          <el-table-column label="操作">
            <template slot-scope="{row}">
              <el-button type="text" icon="el-icon-view" @click="handleView(row)">详细</el-button>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <!-- 操作日志详细 -->
    <el-dialog title="操作日志详细" :visible.sync="open" width="60%" append-to-body>
      <el-row :gutter="15">
        <el-col :span="12">
          <el-form ref="form" :model="form" label-width="100px" size="mini">
            <el-row>
              <el-col :span="12">

                <el-form-item label="操作模块：">{{ form.channel }} </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="登录信息："> {{form.user_id}}（用户） / {{ form.real_ip }}（客户IP） </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="请求方式：">{{ form.method }} （服务IP：{{form.server_addr}}）</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="访问设备：">{{ form.user_agent||'' }}</el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="操作方法：">{{ form.uri }}</el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="请求参数：">{{ form.arguments }}</el-form-item>
                <el-form-item label="请求大小：">{{ form.request_body_size_alias }}</el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="返回参数：">{{ form.message }}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="响应大小：">{{ form.response_body_size_alias }}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="执行时间：">{{ form.execution_time_alias }}</el-form-item>
              </el-col>


              <!--          <el-col :span="12">-->
              <!--            <el-form-item label="操作状态：">-->
              <!--              <div v-if="form.status === 1">正常</div>-->
              <!--              <div v-else>失败</div>-->
              <!--            </el-form-item>-->
              <!--          </el-col>-->

              <el-col :span="24">
                <el-form-item label="操作时间：">{{ $utils.parseTime(form.unix_time) }}</el-form-item>
              </el-col>

            </el-row>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-input type="textarea" rows="30" :value="JSON.stringify(form,null,4)">
          </el-input>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'

export default {
  name: 'SysLogList',
  components: {
    PageHeaderLayout,
    ApeTable
  },
  data() {
    return {
      loadingStaus: true,
      columns: [
        {
          title: 'ID',
          value: 'id',
          width: 88,
          header_align: 'center',
          align: 'center'
        },
        {
          title: '用户/访问',
          value: [
            {label: '用户：', value: 'user_id_alias', value_alias: 'user_id'},
            {label: 'QID：', value: 'qid_alias', value_alias: 'qid'},
            {label: '类型：', value: 'channel_alias', value_alias: 'channel'},
            {label: '路径：', value: 'uri_alias', value_alias: 'uri'},
          ],
          header_align: 'center',
          width: 320
        },
        {
          title: '访问设备',
          value: [
            {label: '设备：', value: 'platform_device'},
            {label: '浏览器：', value: 'browser'},
            {label: '日志级别：', value: 'level_name'},
          ],
          header_align: 'center',
          width: 220
        },
        {
          title: '请求响应',
          value: [
            {label: '方法：', value: 'method'},
            {label: '请求大小：', value: 'request_body_size_alias'},
            {label: '响应大小：', value: 'response_body_size_alias'},
            {label: '执行时间：', value: 'execution_time_alias'},
          ],
          header_align: 'center',
          width: 180
        },
        {
          title: '传参/MSG',
          value: [
            {label: '传参：', value: 'arguments_alias', value_alias: 'arguments'},
            {label: 'MSG：', value: 'message_alias', value_alias: 'message'},
          ],
          header_align: 'center',
          width: 280
        },
        {
          title: 'IP/时间',
          value: [
            {label: '客户IP：', value: 'real_ip'},
            {label: '服务IP：', value: 'server_addr'},
            {label: '访问时间：', value: 'created_at'}
          ],
          header_align: 'center',
          width: 240
        }
      ],
      // 搜索条件
      searchCondition: {},
      // 日志列表
      sysLogList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 表单参数
      form: {},
      // 是否显示弹出层
      open: false,
    }
  },
  computed: {},
  methods: {
    // 搜索
    searchButton() {
      this.initSysLogList()
    },
    // 切换页码操作
    async switchPaging() {
      this.initSysLogList()
    },
    // 初始化附件列表
    async initSysLogList(type) {
      this.loadingStaus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo)
      let {list, pages} = await this.$api.getSysLogList(searchCondition)
      this.sysLogList = []
      this.$nextTick(() => {
        this.sysLogList = list
      })
      this.pagingData.total = pages.total
      this.loadingStaus = false
    },
    async clearLogBtn() {
      this.$confirm('此操作将永久删除日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.clearSysLog()
        if (info) {
          this.$notify.success('清空成功')
          this.searchButton()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.qid) {
        condition.qid = this.searchCondition.qid
      }
      if (this.searchCondition.channel) {
        condition.channel = this.searchCondition.channel
      }
      if (this.searchCondition.user_id) {
        condition.user_id = this.searchCondition.user_id
      }
      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      return condition
    },
    /** 详细按钮操作 */
    handleView(row) {
      this.open = true;
      this.form = row;
    },
  },
  mounted() {
    this.initSysLogList()
  },
}
</script>
